.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #eec61f;
}

.logo {
  padding-bottom: 60%;
}
